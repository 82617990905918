import { SelectOption, SelectOptions } from "@/components/SelectInput";
import {
  BooleanFilterConfiguration,
  DateFilterConfiguration,
  DriverFilterConfiguration,
  EmployeeFilterConfiguration,
  ExclusiveSelectFilterConfiguration,
  FilterBoolean,
  FilterConfiguration,
  FilterOperatorType,
  FilterType,
  NumberFilterConfiguration,
  SelectFilterConfiguration,
  StringFilterConfiguration,
  TeamFilterConfiguration,
} from "@/components/advanced-filters/FilterConfiguration";
import { isArray, isDate as isDateType, isString, isNumber, isBoolean, isObject, truncate } from "lodash";
import { filterCustomDecodeValue, filterCustomEncodeValue } from "@/helpers/queryParams";
import { FilterValues, UseAdvancedFiltersState } from "@/components/advanced-filters/useAdvancedFilters";
import { getTeam } from "@/services/teamsService";
import { TFunction } from "i18next";
import { newDate } from "@/utils/newDate";
import { myTeamTypeLocale, shortTeamTypeLocale } from "@/utils/localizeConstants";
import { DateRange } from "react-day-picker";
import { TeamType } from "@/types/common";
import { User } from "@/types/user";

export type OneOf<T> = T extends (infer U)[] ? U : T;

export function isDateRange(value: unknown): value is DateRange {
  return Boolean(value && typeof value === "object" && "from" in value);
}

export function getFilterConfigurationByFieldName(
  field: string | undefined,
  filters: FilterConfiguration[]
): FilterConfiguration | undefined {
  return filters.find((filter) => filter.name === field);
}

export function dateToString(date?: Date): string | null {
  if (!date) return null;
  return date.toISOString().split("T")[0];
}

type DefaultOptionValue =
  | DateFilterConfiguration["defaultValue"]
  | StringFilterConfiguration["defaultValue"]
  | NumberFilterConfiguration["defaultValue"]
  | BooleanFilterConfiguration["defaultValue"]
  | EmployeeFilterConfiguration["defaultValue"]
  | (SelectOption | SelectOption[]);

export function mapValueToString(value: DefaultOptionValue | null): string | null {
  if (isString(value)) return value;
  if (isDateType(value)) return dateToString(value);
  if (isNumber(value) || isBoolean(value)) return value.toString();
  if (isDateRange(value)) return `${dateToString(value.from)},${dateToString(value.to)}`;
  if (isArray(value))
    return value.map((a) => mapValueToString(isDateType(a) ? a : filterCustomEncodeValue(a!))).join(",");
  // Select Option
  if (isObject(value) && value.hasOwnProperty("value")) {
    return mapValueToString(value["value"]);
  }

  return null;
}

export function mapToSelectOption(value: string): SelectOption {
  return { label: value, value };
}

export const getAsArray = <T>(value?: T | T[]): T[] => {
  if (!value) return [];
  return isArray(value) ? value : [value];
};

export const getFirstValue = <T>(value?: T | T[]): T | undefined => {
  return isArray(value) ? value[0] : value;
};

export const isEnumValue = <T extends Record<string, string>>(enumType: T, value: unknown): value is T => {
  return Object.values(enumType).includes(value as T[keyof T]);
};

export function isSelectTypeConfiguration(filter: FilterConfiguration): filter is SelectFilterConfiguration {
  return filter.type === FilterType.SELECT;
}

export function isExclusiveSelectTypeConfiguration(
  filter: FilterConfiguration
): filter is ExclusiveSelectFilterConfiguration {
  return filter.type === FilterType.EXCLUSIVE_SELECT;
}

export function isEmployeeTypeConfiguration(filter: FilterConfiguration): filter is EmployeeFilterConfiguration {
  return filter.type === FilterType.EMPLOYEE;
}

export function isDriverTypeConfiguration(filter: FilterConfiguration): filter is DriverFilterConfiguration {
  return filter.type === FilterType.DRIVER;
}

export function isTeamTypeConfiguration(filter: FilterConfiguration): filter is TeamFilterConfiguration {
  return filter.type === FilterType.TEAM;
}

export function isFilterOperator(operator: string): operator is FilterOperatorType {
  return Object.values(FilterOperatorType).includes(operator as FilterOperatorType);
}

/**
 * Extracts the operator from a key
 * @returns the operator and the key without the operator
 * @warning It assumes that the operator is valid.
 * @example extractOperatorFromKey("date") => ["date", "eq"]
 * @example extractOperatorFromKey("date[eq]") => ["date", "eq"]
 * @example extractOperatorFromKey("date[between]") => ["date", "between"]
 */
export function extractOperatorFromKey(key: string): [string, FilterOperatorType] {
  let [field, operator] = key.split("[");
  operator = operator?.replace("]", "") ?? null;

  if (isFilterOperator(operator)) {
    return [field, operator];
  }
  return [key, FilterOperatorType.EQUALS];
}

export const getLabelFromSelectOptionsOrValue = (value: string, options: SelectOptions): string =>
  options.find((o) => o.value === value)?.label.toString() ?? value;

export async function getOptionsForFilter(
  t: TFunction,
  filter: FilterConfiguration,
  value: string,
  filtersState: UseAdvancedFiltersState,
  user: User | null
): Promise<SelectOptions> {
  if (isEmployeeTypeConfiguration(filter)) {
    return (await filtersState.getValuesForFilter(filter)).map((e) => ({ value: e.id, label: e.name }));
  }

  if (filter.type === FilterType.TEAM) {
    const team = await getTeam(value);
    const isMyOwnTeam =
      !!team && !!user && team.type !== TeamType.CUSTOM && team.managers.some((m) => m.id === user.employeeReference);
    if (isMyOwnTeam)
      return [{ value: team.id, label: myTeamTypeLocale(t, team.type as Exclude<TeamType, TeamType.CUSTOM>) }];

    return [{ value: team.id, label: `${team.name} (${shortTeamTypeLocale(t, team.type)})` }];
  }

  return filtersState.getValuesForFilter(filter);
}

const formatMultipleValueLabel = <T extends string>(
  value: T | T[],
  limitTo: number = 2,
  mapLabel?: (v: T) => string
): string => {
  const valuesArray = (isArray(value) ? value : value.split(",")).map(filterCustomDecodeValue);

  const mappedValues = (mapLabel ? valuesArray.map(mapLabel) : valuesArray).map((v) => truncate(v, { length: 30 }));

  if (mappedValues.length <= limitTo) return `${mappedValues.join(", ")}`;
  return `${mappedValues.slice(0, limitTo).join(", ")}, +${mappedValues.length - limitTo}`;
};

export const formatValue = (
  t: TFunction,
  type: FilterType,
  operator: FilterOperatorType,
  value: string,
  getLabelFromOptions: (value: string) => string
) => {
  if (type === FilterType.BOOLEAN) {
    return value === FilterBoolean.TRUE
      ? t("common.filters.pills.boolean.true")
      : t("common.filters.pills.boolean.false");
  }

  if (type === FilterType.DATE) {
    if (operator === FilterOperatorType.BETWEEN) {
      const [from, to] = value.split(",");

      if (from === to) return from; // Same day

      return t("common.filters.pills.date.between", { from, to });
    }
    return value;
  }

  if (type === FilterType.EMPLOYEE || type === FilterType.USER || type === FilterType.TEAM) {
    return getLabelFromOptions(value);
  }

  const isMultipleValueOperator = [FilterOperatorType.IN, FilterOperatorType.NOT_IN].includes(operator);

  if (type === FilterType.SELECT || type === FilterType.EXCLUSIVE_SELECT || type === FilterType.DRIVER) {
    return isMultipleValueOperator
      ? formatMultipleValueLabel(value, 2, getLabelFromOptions)
      : getLabelFromOptions(filterCustomDecodeValue(value));
  }

  return isMultipleValueOperator ? formatMultipleValueLabel(value) : value;
};

const relativeDateRegex: RegExp =
  /^\s*(([+-])\s*(\d+)\s*(d|day|days|w|week|weeks|m|month|months|q|quarter|quarters|y|year|years)|now)(?:\s*:\s*([es])o([mqwy]))?\s*$/;

export function isValidDynamicDate(value: string): boolean {
  if (!value) return false;
  if (value.includes(",")) {
    const [from, to] = value.split(",");
    return isValidDynamicDate(from) && isValidDynamicDate(to);
  }

  return relativeDateRegex.test(value);
}

export const dateOrNull = (date: string | null) => (date ? newDate(date) : null);

export const mapTargetRulesToRowValue = (filters: Record<string, any>): FilterValues => {
  return Object.entries(filters).reduce((acc, [key, value]) => {
    const [field, operator] = extractOperatorFromKey(key);
    return {
      ...acc,
      [field]: {
        operator: operator,
        value: Array.isArray(value) ? value.join(",") : String(value),
      },
    };
  }, {} as FilterValues);
};
