import { Severity } from "@/types/common";

export const severityColorMap: Record<Severity, string> = {
  high: "#ff6659",
  medium: "#ffad42",
  low: "#ffe005",
};

export function severityColor(severity: Severity): string {
  return severityColorMap[severity];
}
