import type { SidebarSections } from "@/types/sidebar";
import TrackChangesRoundedIcon from "@mui/icons-material/TrackChangesRounded";
import HouseRounded from "@mui/icons-material/HouseRounded";
import PieChartIcon from "@mui/icons-material/PieChart";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import RateReviewIcon from "@mui/icons-material/RateReview";
import QuizIcon from "@mui/icons-material/Quiz";
import { OldRoleCode } from "@/types/user";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { SplitTreatment } from "@/hooks/useSplitTreatment";
import { MANAGER_TEAM_COMPARISON_SECTION } from "@/modules/skeleton/components/sidebar/DashboardSidebar";
import { JWTUser } from "@alanszp/jwt";
import { CommunicationsPermissions, FeedbackTypeListPermissions } from "@/types/common";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import DescriptionIcon from "@mui/icons-material/Description";
import ViewListIcon from "@mui/icons-material/ViewList";
import ForumIcon from "@mui/icons-material/Forum";
import ArticleIcon from "@mui/icons-material/Article";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { PermissionCode } from "@/types/PermissionCode";
import AdjustIcon from "@mui/icons-material/Adjust";

export const DEFAULT_CASES_LIST_URL = "/cases?status%5Bin%5D=detected%2CinProgress";

export const getSections = (): SidebarSections => {
  return [
    {
      items: [
        {
          name: "home",
          path: "/",
          label: (t) => t("home.titleForSidebar"),
          icon: <HouseRounded fontSize="medium" />,
          showRules: {
            roles: [OldRoleCode.ADMIN, OldRoleCode.HRBP, OldRoleCode.VIEWER],
          },
        },
        {
          name: "myTeam",
          path: "/my/team-insights",
          label: (t) => t("team.myTeam.title.title"),
          icon: <GroupWorkIcon fontSize="medium" />,
          showRules: {
            permissions: {
              code: PermissionCode.FRONT_SECTION_MANAGER_MY_TEAM_VIEW,
              strategy: "single",
            },
          },
        },
        {
          name: MANAGER_TEAM_COMPARISON_SECTION,
          path: "/my/comparison",
          label: (t) => t("team.comparison.title"),
          icon: <CompareArrowsIcon fontSize="medium" />,
          showRules: {
            permissions: {
              code: PermissionCode.FRONT_SECTION_MANAGER_TEAM_COMPARISON_VIEW,
              strategy: "single",
            },
          },
        },
        {
          name: "cases",
          path: DEFAULT_CASES_LIST_URL,
          label: (t) => t("caseManagement.home.title"),
          icon: <TrackChangesRoundedIcon fontSize="medium" />,
          showRules: {
            permissions: { strategy: "single", code: PermissionCode.CASE_LIST },
            roles: [OldRoleCode.ADMIN, OldRoleCode.HRBP],
            treatments: {
              values: [SplitTreatment.EXPERIENCE_MODULE_ACTIVATED],
            },
          },
        },
        {
          name: "tickets",
          path: "/tickets",
          label: (t) => t("ticket.list.title"),
          icon: <AdjustIcon fontSize="medium" />,
          showRules: {
            permissions: { strategy: "single", code: PermissionCode.TICKET_ACCESS },
          },
        },
        {
          name: "teams",
          path: "/teams",
          label: (t) => t("teams.home.title"),
          icon: <GroupsIcon fontSize="medium" />,
          showRules: {
            permissions: {
              strategy: "single",
              code: PermissionCode.FRONT_SECTION_TEAMS_VIEW,
            },
            treatments: {
              values: [SplitTreatment.EXPERIENCE_MODULE_ACTIVATED],
            },
          },
        },
        {
          name: "questions",
          path: "/questions",
          label: (t) => t("questions.home.title"),
          icon: <QuestionAnswerIcon fontSize="medium" />,
          showRules: {
            permissions: {
              strategy: "single",
              code: PermissionCode.REPORTING_QUERY_METRIC_QUESTION_SUMMARY,
            },
            treatments: {
              values: [SplitTreatment.EXPERIENCE_MODULE_ACTIVATED],
            },
          },
        },
        {
          name: "feedback",
          path: "/feedback",
          label: (t) => t("feedback.home.title"),
          icon: <RateReviewIcon fontSize="medium" />,
          showRules: {
            permissions: {
              code: FeedbackTypeListPermissions,
              strategy: "some",
            },
          },
        },
        {
          name: "integrations",
          path: "/settings/integrations",
          label: (t) => t("settings.tabs.integrations"),
          icon: <IntegrationInstructionsIcon fontSize="medium" />,
          showRules: {
            roles: [OldRoleCode.INTEGRATIONS],
          },
        },
        {
          name: "helpdesk",
          path: "/helpdesk",
          label: (t) => t("faqs.sidebar.item"),
          icon: <QuizIcon fontSize="medium" />,
          showRules: {
            treatments: {
              values: [SplitTreatment.FAQS_ACTIVATED],
            },
            permissions: {
              code: [
                "reporting.metric.faqsByMenu:query",
                "reporting.metric.faqsUseful:query",
                "reporting.metric.totalFaqsQueries:query",
                "helpdesk.knowledge:list",
              ],
              strategy: "some",
            },
          },
          children: [
            {
              name: "helpdesk/metrics",
              path: "/helpdesk/metrics",
              label: (t) => t("faqs.home.sidebarTitle"),
              icon: <LeaderboardIcon fontSize="medium" />,
              showRules: {
                treatments: {
                  values: [SplitTreatment.FAQS_ACTIVATED],
                },
                permissions: {
                  code: [
                    "reporting.metric.faqsByMenu:query",
                    "reporting.metric.faqsUseful:query",
                    "reporting.metric.totalFaqsQueries:query",
                  ],
                  strategy: "some",
                },
              },
            },
            {
              name: "helpdesk/knowledge",
              path: "/helpdesk/knowledge",
              label: (t) => t("helpdesk.list.sidebar.item"),
              icon: <ArticleIcon fontSize="medium" />,
              showRules: {
                treatments: {
                  values: [SplitTreatment.FAQS_ACTIVATED],
                },
                permissions: {
                  code: ["helpdesk.knowledge:list"],
                  strategy: "every",
                },
              },
            },
          ],
        },

        {
          name: "board",
          path: "/board",
          label: (t) => t("board.sidebar.title"),
          icon: <DashboardIcon fontSize="medium" />,
          showRules: {
            roles: [OldRoleCode.ADMIN, OldRoleCode.HRBP, OldRoleCode.VIEWER],
            treatments: {
              values: [SplitTreatment.CUSTOM_DASHBOARD],
            },
          },
        },
        {
          name: "reporting",
          path: "/reporting",
          label: (t) => t("reporting.tab.title"),
          icon: <PieChartIcon fontSize="medium" />,
          showRules: {
            permissions: {
              code: PermissionCode.FRONT_SECTION_REPORTING_VIEW,
              strategy: "single",
            },
          },
        },
        {
          name: "employees",
          path: "/employees",
          label: (t) => t("employees.title"),
          icon: <PersonSearchIcon fontSize="medium" />,
          showRules: {
            roles: [OldRoleCode.ADMIN, OldRoleCode.HRBP],
          },
        },
        {
          name: "communications",
          path: "/communications",
          label: (t) => t("communications.title"),
          icon: <ModeCommentIcon fontSize="medium" />,
          showRules: {
            permissions: {
              code: [
                CommunicationsPermissions.CREATE_ONETIME,
                CommunicationsPermissions.TEMPLATE_LIST,
                CommunicationsPermissions.COMMUNICATIONS_SENDING_LIST,
              ],
              strategy: "some",
            },
          },
          children: [
            {
              name: "communications",
              path: "/communications/create",
              label: (t) => t("communications.create.sidebarTitle"),
              icon: <ForumIcon fontSize="medium" />,
              showRules: {
                permissions: {
                  code: CommunicationsPermissions.CREATE_ONETIME,
                  strategy: "single",
                },
              },
            },
            {
              name: "communications/templates",
              path: "/communications/templates",
              label: (t) => t("communications.templates.title"),
              icon: <DescriptionIcon fontSize="medium" />,
              showRules: {
                permissions: {
                  code: CommunicationsPermissions.TEMPLATE_LIST,
                  strategy: "single",
                },
              },
            },
            {
              name: "communications/results",
              path: "/communications/results",
              label: (t) => t("communications.results.sidebarTitle"),
              icon: <ViewListIcon fontSize="medium" />,
              showRules: {
                permissions: {
                  code: CommunicationsPermissions.COMMUNICATIONS_SENDING_LIST,
                  strategy: "single",
                },
              },
            },
          ],
        },
      ],
    },
  ];
};

// import DescriptionIcon from '@mui/icons-material/Description';
// import ForumIcon from '@mui/icons-material/Forum';

export function getSidebarSections(jwtUser: JWTUser | null): SidebarSections {
  return getSections()
    .map((section) => {
      const items = section.items.filter(({ showRules }) =>
        showRules?.roles ? jwtUser?.hasRoles(showRules.roles) ?? false : true
      );
      return { items };
    })
    .filter((e) => e.items.length > 0);
}
