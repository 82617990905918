import { useMemo, type FC } from "react";
import { Chip, ChipProps, useTheme } from "@mui/material";
import { Severity } from "@/types/common";
import { useTranslation } from "react-i18next";
import { riskCaseSeverityLocale } from "../utils/localizeConstants";
import { severityColor } from "@/theme/severityColor";

interface SeverityChipProps extends Exclude<ChipProps, "color"> {
  severity?: Severity | null;
  variant?: "outlined" | "filled";
}

export const SeverityChip: FC<SeverityChipProps> = ({ severity, size = "small", sx, ...other }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const severityPalette = useMemo(() => {
    if (!severity) return theme.palette.error;
    return theme.palette.augmentColor({
      name: "severity",
      color: {
        main: severityColor(severity),
      },
    });
  }, [theme, severity]);

  if (!severity) return <>-</>;

  return (
    <Chip
      size={size}
      variant="outlined"
      label={riskCaseSeverityLocale(t, severity)}
      sx={{
        ...sx,
        borderColor: "currentcolor",
        background: `${severityPalette.main}30`,
        color: severityPalette.dark,
      }}
      {...other}
    />
  );
};
