import useAuth from "@/hooks/useAuth";
import { SplitTreatment, useBooleanTreatment } from "@/hooks/useSplitTreatment";
import { useTheme } from "@mui/material";
import { useEffect } from "react";

const DEV_REV_PLUG_APP_ID = "don:core:dvrv-us-1:devo/1xq8rwyxuu:plug_setting/1";

export const DevRevPlug = () => {
  const theme = useTheme();
  const {
    withPermissions: { devRevPlugSessionToken },
  } = useAuth();

  const [devRevPlugSessionEnabled, state] = useBooleanTreatment(SplitTreatment.DEV_REV_PLUG_SESSION_ENABLED);

  const plugSDK = (window as any).plugSDK;

  useEffect(() => {
    if (!plugSDK) return;

    if (plugSDK.isPlugInitialized) {
      plugSDK.shutdown();
    }

    // If sessionToken is undefined, the plugSDK will not load the tenant, but the plug still works
    const sessionToken = devRevPlugSessionEnabled ? devRevPlugSessionToken : undefined;

    plugSDK.init({
      // Please ensure you replace the app_id with your unique app id
      app_id: DEV_REV_PLUG_APP_ID,
      session_token: sessionToken,
      accent_color: theme.palette.primary.main,
    });
  }, [plugSDK, devRevPlugSessionEnabled, devRevPlugSessionToken, state]);

  return null;
};
