const hashCode = (str: string): number => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

/**
 * Similar but different hash function
 * Algorithm: djb2 (Daniel J. Bernstein, Times 33)
 * This ensures better distribution of hash codes
 * @param str
 * @returns Hash code
 */
export function djb2(str: string): number {
  var hash = 5381;
  for (var i = 0; i < str.length; i++) {
    hash = (hash << 5) + hash + str.charCodeAt(i);
  }
  return hash;
}

export default hashCode;
