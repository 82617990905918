import { DateRangeEnumTypeConfig, getEmployeeFieldValues } from "@/services/employeeService";
import { isDate, parseISO } from "date-fns";
import DOMPurify from "dompurify";
import { TFunction } from "i18next";
import { isNil, isNull, omit } from "lodash";
import { AutocompleteOption } from "../InputAutocomplete";
import { AutocompleteFilterConfiguration } from "./FilterConfiguration";
import { FilterType } from "./useFilters";
import { EmployeeFieldTranslationGetter, EmployeeFieldWithTranslation } from "@/hooks/useEmployeeFields";

export const parseArrayValue = (value: string): string | Date | boolean | string[] => {
  if (isNil(value)) return [];
  return value?.includes(",") ? value.split(",") : value;
};

export function assertParseSpecialFormatValue(value: string): string | Date | boolean | string[] {
  let possibleFormat: string | Date | boolean | string[] = decodeURIComponent(value.replace(/\+/g, "%20"));

  if (isDate(possibleFormat)) {
    return parseISO(possibleFormat);
  }

  if (possibleFormat === "false") return false;
  if (possibleFormat === "true") return true;

  possibleFormat = parseArrayValue(possibleFormat);

  return possibleFormat;
}

export function safeSetValuesFromUrl<T extends object>(
  searchParams: URLSearchParams,
  defaultValues: Record<string, string | number | Date | boolean | string[]>
): T {
  const newValues = {};
  Object.keys(defaultValues).forEach((key) => {
    const value = searchParams.get(key);

    if (!isNull(value)) {
      const safeValue = DOMPurify.sanitize(value); // prevent xss attack

      newValues[key] = safeValue === "" ? null : assertParseSpecialFormatValue(safeValue);
    } else {
      newValues[key] = defaultValues[key];
    }
  });

  return newValues as T;
}

function mapRequestFieldResponseToAutocomplete(response): AutocompleteOption[] {
  return response.fieldValues.map((fieldValue) => ({
    label: fieldValue,
    value: fieldValue,
  }));
}

export function departmentField(
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter
): AutocompleteFilterConfiguration {
  return {
    type: FilterType.AUTOCOMPLETE,
    name: "department",
    label: getEmployeeFieldTranslation("department", t("questions.home.filters.department")),
    fieldConfig: {
      options: (filters) => () =>
        getEmployeeFieldValues("department", omit(filters, ["department"])).then(mapRequestFieldResponseToAutocomplete),
    },
  };
}

export function locationField(
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter
): AutocompleteFilterConfiguration {
  return {
    type: FilterType.AUTOCOMPLETE,
    name: "location",
    label: getEmployeeFieldTranslation("location", t("questions.home.filters.location")),
    fieldConfig: {
      options: (filters) => () =>
        getEmployeeFieldValues("location", omit(filters, ["location"])).then(mapRequestFieldResponseToAutocomplete),
    },
  };
}

export function divisionField(
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter
): AutocompleteFilterConfiguration {
  return {
    type: FilterType.AUTOCOMPLETE,
    name: "division",
    label: getEmployeeFieldTranslation("division", t("questions.home.filters.division")),
    fieldConfig: {
      options: (filters) => () =>
        getEmployeeFieldValues("division", omit(filters, ["division"])).then(mapRequestFieldResponseToAutocomplete),
    },
  };
}

export function subDepartmentField(
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter
): AutocompleteFilterConfiguration {
  return {
    type: FilterType.AUTOCOMPLETE,
    minWidth: "200px",
    name: "subDepartment",
    label: getEmployeeFieldTranslation("subDepartment", t("questions.home.filters.subDepartment")),
    fieldConfig: {
      options: (filters) => () =>
        getEmployeeFieldValues("subDepartment", omit(filters, ["subDepartment"])).then(
          mapRequestFieldResponseToAutocomplete
        ),
    },
  };
}

export function countryField(t: TFunction): AutocompleteFilterConfiguration {
  return {
    type: FilterType.AUTOCOMPLETE,
    name: "country",
    label: t("questions.home.filters.country"),
    fieldConfig: {
      options: (filters) => () =>
        getEmployeeFieldValues("country", omit(filters, ["country"])).then(mapRequestFieldResponseToAutocomplete),
    },
  };
}

export function tenureField(
  t: TFunction,
  employeeField: EmployeeFieldWithTranslation,
  locale: string
): AutocompleteFilterConfiguration {
  return {
    type: FilterType.AUTOCOMPLETE,
    name: "tenure",
    label: t("questions.home.filters.tenure"),
    fieldConfig: {
      options: (employeeField.typeConfig as DateRangeEnumTypeConfig).steps.map((step) => ({
        label: step.label[locale],
        value: step.name,
      })),
    },
  };
}
