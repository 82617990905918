export enum PermissionCode {
  // User permissions
  USER_IMPERSONATE = "user:impersonate",
  USER_EDIT = "user:edit",
  USER_DELETE = "user:delete",
  USER_CREATE = "user:create",
  USER_RESET_PASSWORD = "user:resetPassword",

  // Organization permissions
  ORGANIZATION_EDIT = "organization:edit",

  // Contacts
  CONTACT_OFFBOARDING_LIST = "contact.offboarding:list",

  // Front sections
  FRONT_SECTION_TEAMS_VIEW = "front.section.teams:view",
  FRONT_SECTION_REPORTING_VIEW = "front.section.reporting:view",
  FRONT_SECTION_MANAGER_MY_TEAM_VIEW = "front.section.manager.myTeam:view",
  FRONT_SECTION_MANAGER_TEAM_COMPARISON_VIEW = "front.section.manager.teamComparison:view",

  // File
  FILE_INTEGRATION_SAMPLE = "file.integration:sample",

  // Boards
  BOARD_EDIT = "board:edit",
  BOARD_SKIP_ACCESS_CHECK = "board:skipAccessCheck",

  // Roles
  ROLE_EDIT = "role:edit",
  ROLE_CREATE = "role:create",
  ROLE_PERMISSION_EDIT = "role.permission:edit",
  ROLE_HOME_BOARD_EDIT = "role.homeBoard:edit",
  ROLE_LIST = "role:list",

  // Integration Channel Permissions
  INTEGRATION_CHANNEL_LIST = "integration.channel:list",
  INTEGRATION_CHANNEL_SYNC = "integration.channel:sync",
  INTEGRATION_CHANNEL_EDIT = "integration.channel:edit",
  INTEGRATION_CHANNEL_CREATE = "integration.channel:create",
  INTEGRATION_CHANNEL_VIEW = "integration.channel:view",
  INTEGRATION_CHANNEL_HISTORY_VIEW = "integration.channel.history:view",

  // Integration HRMS Permissions
  INTEGRATION_HRMS_LIST = "integration.hrms:list",
  INTEGRATION_HRMS_SYNC = "integration.hrms:sync",
  INTEGRATION_HRMS_EDIT = "integration.hrms:edit",
  INTEGRATION_HRMS_CREATE = "integration.hrms:create",
  INTEGRATION_HRMS_VIEW = "integration.hrms:view",
  INTEGRATION_HRMS_HISTORY_VIEW = "integration.hrms.history:view",

  // Chat
  CHAT_TEST_LIST = "chat.test:list",
  CHAT_NPS_LIST = "chat.nps:list",
  CHAT_ONE_TIME_LIST = "chat.oneTime:list",
  CHAT_EXPERIENCE_LIST = "chat.experience:list",
  CHAT_ONBOARDING_LIST = "chat.onboarding:list",
  CHAT_OFFBOARDING_LIST = "chat.offboarding:list",
  CHAT_FEEDBACK_LIST = "chat.feedback:list",
  CHAT_LARA_BOT_CREATE = "chat.laraBot:create",
  CHAT_ANSWER_VIEW = "chat.answer:view",

  // Cases
  CASE_CREATE = "case:create",
  CASE_EDIT = "case:edit",
  CASE_LIST = "case:list",
  CASE_CLOSED_REASON_CREATE = "case.closedReason:create",

  // Cases Notes
  CASE_NOTES_ADD = "case.notes:add",
  CASE_NOTES_EDIT = "case.notes:edit",
  CASE_NOTES_REMOVE = "case.notes:remove",
  CASE_NOTES_VIEW = "case.notes:view",
  CASE_SHOW_WITHOUT_MODULE = "case:showWithoutModule",

  // Feedback summary
  FEEDBACK_SUMMARY_LIST = "feedback.summary:list",
  FEEDBACK_SUMMARY_CREATE = "feedback.summary:create",
  FEEDBACK_SUMMARY_VIEW = "feedback.summary:view",

  // Ticket
  TICKET_ACCESS = "ticket:access",
  TICKET_SKIP_ACCESS_CHECK = "ticket:skipAccessCheck",

  // Ticket type
  TICKET_TYPE_CREATE = "ticket.type:create",
  TICKET_TYPE_VIEW = "ticket.type:view",
  TICKET_TYPE_LIST = "ticket.type:list",
  TICKET_TYPE_EDIT = "ticket.type:edit",
  TICKET_TYPE_ARCHIVE = "ticket.type:archive",

  // Scheduler rules
  CREATE_RULES = "scheduler.rules:create",
  LIST_RULES = "scheduler.rules:list",
  MODIFY_RULES = "scheduler.rules:modify",
  DELETE_RULES = "scheduler.rules:delete",
  // Scheduler triggers
  CREATE_TRIGGERS = "scheduler.triggers:create",
  LIST_TRIGGERS = "scheduler.triggers:list",
  EDIT_TRIGGERS = "scheduler.triggers:edit",
  REMOVE_TRIGGERS = "scheduler.triggers:remove",

  // Reporting
  REPORTING_QUERY_METRIC_QUESTION_SUMMARY = "reporting.metric.questionsSummary:query",
  REPORTING_QUERY_METRIC_ANSWERS_BY_QUESTIONS = "reporting.metric.answersByQuestions:query",

  // Questions
  QUESTION_VIEW = "question:view",

  // Clerk Prompts
  PROMPT_CREATE = "clerk.prompt:create",
  PROMPT_LIST = "clerk.prompt:list",
  PROMPT_EDIT = "clerk.prompt:edit",
}
