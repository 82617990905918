export const HELP_LINKS = {
  home: "https://help.meetlara.ai",
  laraScore: "https://support.devrev.ai/meetlara/article/ART-41-qu-es-el-lara-score",
  drivers: "https://support.devrev.ai/meetlara/article/ART-9-drivers-y-subdrivers",
  customTeams: "https://support.devrev.ai/meetlara/article/ART-57-sobre-los-equipos-personalizados",
  feedback: "https://support.devrev.ai/meetlara/article/ART-68-feedback",
  enps: "https://support.devrev.ai/meetlara/article/ART-36-qu-es-el-enps-y-cmo-se-calcula",
  reports: "https://support.devrev.ai/meetlara/article/ART-83-reportes",
  graphTeamsAdmins: "https://support.devrev.ai/meetlara/article/ART-74-grficos-comparativos-de-equipos",
  graphTeamsManagers: "https://support.devrev.ai/meetlara/article/ART-18-grfico-comparativo-de-equipos-para-managers",
  dashboardManagers: "https://support.devrev.ai/meetlara/directories/directory-10",
  questions: "https://help.meetlara.ai/meetlara/article/ART-105-",
};
