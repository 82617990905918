import { omit, uniqBy } from "lodash";
import { searchUsers, SearchUsersParams } from "@/services/userService";
import { User } from "@/types/user";
import { FC, useEffect, useState } from "react";
import {
  AutocompleteOption,
  InputAutocomplete,
  InputAutocompleteProps,
  OptionWithAvatar,
  isOptionItemLoading,
} from "./InputAutocomplete";
import { usePager } from "@/hooks/usePager";
import { AutocompleteChangeReason, Skeleton } from "@mui/material";
import { useDebounce } from "@/hooks/useDebounce";

const fields = ["id", "employeeReference", "firstName", "lastName", "avatar"] as const;

export type PartialUser = Pick<User, (typeof fields)[number]>;

type AutocompleteOptionWithAvatar = AutocompleteOption & { avatar?: string };

type InputAutocompleteUserProps = InputAutocompleteProps<AutocompleteOption> & {
  readonly?: boolean;
  disabledIds?: string[];
  pageSize?: number;
  onChangeUser?: (value: PartialUser | null) => void;
};

export const InputAutocompleteUser: FC<Omit<InputAutocompleteUserProps, "options">> = ({ ...props }) => {
  const { page, stop, restart, loading, goNextPage, setLoading } = usePager(undefined, props.pageSize);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  const [users, setUsers] = useState<PartialUser[]>([]);

  useEffect(() => {
    let wasUnmounted = false;
    const executeEmployeePromise = async () => {
      setLoading(true);

      const params: SearchUsersParams = {
        fields: fields as unknown as string[],
      };

      if (debouncedSearch) {
        params.s = debouncedSearch;
      }

      const usersResponse = (await searchUsers(params)).elements as PartialUser[];
      if (wasUnmounted) return;

      setUsers((u) => {
        const newEmployees = uniqBy(
          [...u, ...usersResponse.filter((elem) => !(props.disabledIds || [])?.includes(elem.id))],
          "id"
        );

        stop();

        return newEmployees;
      });

      setLoading(false);
    };

    executeEmployeePromise().catch(() => {});

    return () => {
      wasUnmounted = true;
    };
  }, [page, debouncedSearch]);

  const handleSearchChange = (s: string) => {
    if (search === s) return;

    setSearch(s);
    restart();
  };

  const mappedOptions: AutocompleteOptionWithAvatar[] = [
    ...users.map((u) => ({
      value: u.id as string,
      label: `${u.firstName} ${u.lastName}`,
      avatar: u.avatar,
    })),
  ];

  const handleChange = (evt, value: string | null, reason: AutocompleteChangeReason) => {
    props.onChange?.(evt, value, reason);
    if (props.onChangeUser) {
      const selectedUser = users?.find((e) => e.id === value) ?? null;
      props.onChangeUser?.(selectedUser);
    }
  };

  return (
    <InputAutocomplete
      {...omit(props, ["onChange", "onChangeUser", "disabledIds", "pageSize", "disabled", "readonly"])}
      sx={{ minWidth: 260 }}
      options={mappedOptions}
      renderOption={(props, option) =>
        isOptionItemLoading(option) ? (
          <Skeleton key={option.value} variant={"rectangular"} width={"100%"} height={"3px"} />
        ) : (
          <OptionWithAvatar key={option.value} props={props} option={option} />
        )
      }
      handleSearchChange={handleSearchChange}
      onChange={handleChange}
      loadingNextPage={loading}
      onScrollEnd={goNextPage}
      disabled={props.readonly || props.disabled}
    />
  );
};
