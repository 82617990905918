import { isEnumValue } from "@/components/advanced-filters/utils";
import { GROUP_BY_SEARCH_PARAM_KEY } from "@/components/group-by/useGroupBy";
import { dashboardAxios as axios } from "@/lib/axios";
import { MetricDataOrder } from "@/modules/board/models/metricDataSource";
import fileDownload from "js-file-download";
import { isEmpty, isNil } from "lodash";

export const REPORT_NULL_VALUE = "__null__" as const;

export enum ReportScopeType {
  ORG = "org",
  HISTORICAL_SEGMENT = "historical_segment",
  CURRENT_SEGMENT = "current_segment",
}

export enum ReportType {
  DRIVER = "drivers",
  SUB_DRIVER = "subdrivers",
  ENGAGEMENT = "engagement",
  UNRESPONSIVE_EMPLOYEES = "unresponsiveEmployees",
  FAQS_BY_MENU = "faqsByMenu",
  FAQS_USEFUL = "faqsUseful",
  TOTAL_FAQS_QUERIES = "totalFaqsQueries",
  QUESTIONS_SUMMARY = "questionsSummary",
  EMPLOYEE_ANSWERS = "employeeAnswers",
  ANSWERS_BY_QUESTIONS = "answersByQuestions",
  METRIC_ENPS_SCORE = "metricEnpsScore",
  METRIC_ENPS_ANSWERS = "metricEnpsAnswers",
  METRIC_QUESTIONS = "metricQuestions",
  METRIC_RESPONSE_RATE = "metricResponseRate",
  METRIC_CASES = "metricCases",
  METRIC_MOOD = "metricMood",
  METRIC_MOOD_ANSWERS = "metricMoodAnswers",
  METRIC_DRIVER_SCORE = "metricDriverScore",
  METRIC_DRIVER_ANSWERS = "metricDriverAnswers",
  METRIC_LARA_SCORE = "metricLaraScore",
  METRIC_CHATS = "metricChats",
  METRIC_EMPLOYEES = "metricEmployees",
  METRIC_ONBOARDING_AND_OFFBOARDING = "metricOnboardingAndOffboarding",
  METRIC_INDIVIDUAL_RESPONSES = "metricIndividualResponses",
  METRIC_HELPDESK_CONVERSATIONS = "metricHelpdeskConversations",
  METRIC_HELPDESK_USED_ITEMS = "metricHelpdeskUsedItems",
  METRIC_TICKETS = "metricTickets",
  LISTING_TICKETS = "listingTickets",
  LISTING_CASES = "listingCases",
  LISTING_FEEDBACKS = "listingFeedbacks",
}

export const ReportTypeInV3 = new Set([
  ReportType.LISTING_TICKETS,
  ReportType.LISTING_CASES,
  ReportType.LISTING_FEEDBACKS,
  ReportType.METRIC_TICKETS,
]);

export enum LocaleEnum {
  ES = "es",
  EN = "en",
  PT = "pt",
}

export type Locale = keyof typeof LocaleEnum;

export function isValidReportLocale(locale: string): locale is Locale {
  return isEnumValue(LocaleEnum, locale);
}

export const REPORT_NO_DATA = "" as const;

export type FetchedReport = Report | typeof REPORT_NO_DATA;

export type Report = {
  headers: Record<string, string>;
  data: Record<string, string | number>[];
};

export type ReportJSONFormat = Record<string, string | number>[];

function parseParams({
  filters,
  groupBy,
  breakdown,
  locale,
  orderBy,
  orderDirection,
  translate = true,
}: FetchReportOptions) {
  return {
    ...(isEmpty(groupBy) || !groupBy ? {} : { [GROUP_BY_SEARCH_PARAM_KEY]: groupBy.join(",") }),
    ...(orderBy ? { orderBy } : {}),
    ...(orderDirection ? { orderDirection } : {}),
    ...(breakdown ? { breakdown } : {}),
    ...(filters ? Object.fromEntries(filters) : {}),
    ...(locale ? { locale } : {}),
    ...(translate ? {} : { translate }), // Only pass translate param if it's false to avoid unnecessary clutter
  };
}

interface FetchReportOptions {
  filters?: URLSearchParams;
  groupBy?: string[];
  orderBy?: string;
  orderDirection?: string;
  frontOrderBy?: MetricDataOrder[];
  breakdown?: string;
  locale?: Locale;
  translate?: boolean;
}
export async function fetchReport(
  reportType: ReportType,
  { filters, groupBy, orderBy, orderDirection, breakdown, locale, translate = true }: FetchReportOptions,
  trackReport?: { boardId?: string; widgetId: string },
  benchmark: boolean = false,
  signal?: AbortSignal
): Promise<Report> {
  const params = parseParams({ filters, groupBy, orderBy, orderDirection, breakdown, locale, translate });
  const response = await axios.get<FetchedReport>(
    `/v${ReportTypeInV3.has(reportType) ? "3" : "2"}/reports/${reportType}/json${benchmark ? "/benchmark" : ""}`,
    {
      params: { ...params, ...trackReport },
      signal,
    }
  );
  // `||` operator is important to coalesce the response to an empty object if it's falsy (do not use ?? here as it will only catch nullish values and "" is not nullish)
  return response.data || { headers: {}, data: [] };
}

export async function downloadReport(
  reportType: ReportType,
  format: "csv" | "xlsx",
  filters?: URLSearchParams,
  groupBy?: string[],
  locale?: Locale,
  fileName?: string
): Promise<void> {
  filters?.append("pageSize", "100000"); // Set a high page size to download all data
  const params = filters || groupBy ? parseParams({ filters, groupBy, locale }) : undefined;
  const response = await axios.get(`/v${ReportTypeInV3.has(reportType) ? "3" : "2"}/reports/${reportType}/${format}`, {
    params,
    responseType: format === "xlsx" ? "blob" : undefined,
  });
  const fileNamePrefix = fileName ? `${fileName}` : `report-${reportType}`;
  const finalFileName = `${fileNamePrefix}-${Date.now()}.${format}`;

  fileDownload(response.data, finalFileName);
}

export async function downloadReportUnresponsiveEmployees(
  reportType: ReportType,
  params?: { viewOrgData?: boolean; locale: string }
): Promise<void> {
  const response = await axios.get(
    `/v${ReportTypeInV3.has(reportType) ? "3" : "2"}/reports/${ReportType.UNRESPONSIVE_EMPLOYEES}/csv`,
    {
      params,
    }
  );

  fileDownload(response.data, `report-${reportType}-${Date.now()}.csv`);
}

interface BasicFiltersWithDate {
  from: string;
  to: string;
  // Basic filters
  [key: string]: any;
}

/**
 * This util function is intended to be used with basic filters
 * This resolves the report need for date range filter and the need to map the filters to the report filters.
 */
export function mapBasicFiltersToReportFilters(params: BasicFiltersWithDate): URLSearchParams {
  const filters = new URLSearchParams();

  const { from, to, ...restOfParams } = params;

  filters.append("date[between]", [from, to].join(","));

  // Rest of filters
  Object.entries(restOfParams).forEach(([key, value]) => {
    if (!isNil(value)) {
      filters.append(key, value.toString());
    }
  });

  return filters;
}
