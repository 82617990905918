import { ThemeName } from "@/types/constants";
import { Typography, useTheme } from "@mui/material";
import { ComponentProps, FC } from "react";

const Kbd: FC<ComponentProps<typeof Typography> & { themeName: ThemeName }> = ({ children, themeName, ...props }) => {
  const theme = useTheme();
  const backgroundColor = themeName === ThemeName.LIGHT ? theme.palette.grey[200] : theme.palette.grey[200];
  const color = themeName === ThemeName.LIGHT ? theme.palette.text.secondary : theme.palette.grey[600];
  const borderColor = theme.palette.divider;

  return (
    <Typography
      component="kbd"
      fontSize={10}
      sx={{
        fontFamily: "monospace",
        borderColor,
        borderStyle: "solid",
        borderWidth: "1px 1px 2px",
        backgroundColor,
        color,
        borderRadius: "0.25rem",
        px: 0.5,
        py: 0.1,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default Kbd;
