import { SyncTitleStatus } from "@/modules/settings/components/integrations/IntegrationSyncResponseResults";
import { TeamPendingChangeAction } from "@/services/teamsService";
import {
  ChatStatus,
  RiskCaseStatus,
  Favorability,
  Module,
  RiskCaseClosedReason,
  Severity,
  IntegrationCommunicationType,
  IntegrationHrisType,
  FeedbackType,
  EnpsFavorability,
  IntegrationMappableField,
  LeavingReasonType,
  ReportsChatStatus,
  SegmentOwnerType,
  TeamType,
  TicketStatus,
  Gender,
} from "@/types/common";
import { OldRoleCode } from "@/types/user";
import { TFunction } from "i18next";
import { getLaraScoreAssessment } from "./laraScore";
import { FilterOperatorType } from "@/components/advanced-filters/FilterConfiguration";
import { GroupByOption } from "@/components/group-by/useGroupBy";
import { Board, DateFieldReference } from "@/modules/board/models/board";
import { EmployeeStateValue } from "@/services/employeeService";
import { SyncError } from "@/services/integrationService";

export function boardCommonGroupByLocale(t: TFunction, field: string): string {
  // i18next-extract-mark-context-next-line ["department", "sub_department", "country", "location", "division", "tenure", "team", "date", "organization", "custom_team", "direct_team", "indirect_team", "performance_score", "employee_grade", "city"]
  return t("board.list.groupByOverride.field", { context: field });
}

export function riskCaseStatusLocale(t: TFunction, status: RiskCaseStatus): string {
  // i18next-extract-mark-context-next-line ["detected", "inProgress", "closed"]
  return t("common.riskCaseStatus.status", { context: status });
}

export function ticketStatusLocale(t: TFunction, status: TicketStatus): string {
  // i18next-extract-mark-context-next-line ["to_do", "doing", "closed"]
  return t("common.ticket.status", { context: status });
}

export function riskCaseSeverityLocale(t: TFunction, status: Severity): string {
  // i18next-extract-mark-context-next-line ["low", "medium", "high"]
  return t("common.riskCaseSeverity.severity", { context: status });
}

export function riskCaseClosedReasonLocale(t: TFunction, reason: RiskCaseClosedReason | string): string {
  // i18next-extract-mark-context-next-line ["action_plan_created","problem_resolved","ignore","resigned","not_a_case", "add_new_one", "duplicated_case"]
  return t("common.riskCaseClosedReason.reason", { context: reason, defaultValue: reason });
}

export function homeCasesCardsTitleLocale(t: TFunction, status: RiskCaseStatus): string {
  // i18next-extract-mark-context-next-line ["detected", "inProgress", "closed"]
  return t("home.casesDetails.cardTitle.cat", { context: status });
}

export function homeCasesCardsTooltipLocale(t: TFunction, status: RiskCaseStatus): string {
  // i18next-extract-mark-context-next-line ["detected", "inProgress", "closed"]
  return t("home.casesDetails.cardTooltip.cat", { context: status });
}

const chatStatusLocaleMapper: Record<ChatStatus, ChatStatus> = {
  [ChatStatus.CREATING]: ChatStatus.STARTED,
  [ChatStatus.ERROR]: ChatStatus.STARTED,
  [ChatStatus.STARTED]: ChatStatus.STARTED,
  [ChatStatus.EXPIRED]: ChatStatus.EXPIRED,
  [ChatStatus.ANSWERED]: ChatStatus.ANSWERED,
  [ChatStatus.PARTIALLY_ANSWERED]: ChatStatus.ANSWERED,
};

export function chatStatusLocale(t: TFunction, status: ChatStatus | ReportsChatStatus): string {
  // i18next-extract-mark-context-next-line ["started", "expired", "answered"]
  return t("common.chatStatus.status", { context: chatStatusLocaleMapper[status] ?? ChatStatus.EXPIRED });
}

export enum FavorabilityGender {
  FEMALE = "female",
  MASCULINE = "masculine",
}

export function favorabilityLocale(
  t: TFunction,
  favorability: Favorability,
  gender = FavorabilityGender.MASCULINE,
  count = 1
): string {
  if (gender === FavorabilityGender.FEMALE) {
    // i18next-extract-mark-context-next-line ["positive","neutral","negative"]
    return t("common.favorability_female.cat", { context: favorability, count });
  }
  // i18next-extract-mark-context-next-line ["positive","neutral","negative"]
  return t("common.favorability.cat", { context: favorability, count });
}

export function commonGroupByOptionLocale(t: TFunction, groupBy: GroupByOption): string {
  // i18next-extract-mark-context-next-line ["department", "country", "tenure", "sub_department", "location", "date", "team", "custom_team", "direct_team", "indirect_team", "performance_score", "employee_grade", "employee", "leaving_reason_type", "job_title", "laraScore", "groupSize", "driver", "organization", "sub_driver", "neverContactedCount", "contactedCount", "answeredCount", "pendingCount", "responseRate", "promoterCount", "detractorCount", "passiveCount", "enpsScore", "avgMoodValue", "comparativeAvgMoodValue", "comparativeGroupSize", "activeEmployees", "contactedEmployees", "chatsAnswered", "chatsCreating", "comparativeActiveEmployees", "comparativeContactedEmployees", "comparativeChatsAnswered", "comparativeChatsCreating", "comparativeResponseRate", "opened_at", "detected_at", "closed_at", "status", "assigned_to_reference", "closed_reason", "severity", "aging", "module", "responsible", "chatStatus", "touchpointName", "totalCases", "totalCasesByEmployee", "totalEmployees", "avgMood", "responseRateChats", "responseRateEmployees", "totalChats", "countAnswers", "favorability", "question_id", "question_module", "touchpoint_name", "answered", "numericAnswer","numericAnswerOrText", "driverScore"]
  return t("common.groupBy.options.option", { context: groupBy });
}

export function labelFieldOptionLocale(t: TFunction, field: string): string {
  // i18next-extract-mark-context-next-line ["groupSize", "driverScore", "laraScore", "enpsScore", "avgMood", "avgMoodValue", "responseRate", "activeEmployees", "chatsAnswered", "chatsCreating", "contactedEmployees", "totalCases", "totalCasesByEmployee", "totalEmployees", "responseRateChats", "responseRateEmployees", "totalChats", "countAnswers", "favorability", "distribution", "totalEmployees", "totalResponses"]
  return t("common.labelField.options.option", { context: field });
}

export function moduleLocale(t: TFunction, module: Module) {
  // i18next-extract-mark-context-next-line ["dev_eng","onboarding","nps", "offboarding", "one_time", "feedback"]
  return t("common.module.cat", { context: module });
}

export function tooltipCompareCategoryLocale(
  t: TFunction,
  category: string,
  replacements?: Record<string, string>
): string {
  // i18next-extract-mark-context-next-line ["organization", "past"]
  return t("common.helpText.comparative.metric", { context: category, ...replacements });
}

export function laraScoreAssessmentLocale(t: TFunction, score: number): string {
  const context = getLaraScoreAssessment(score);
  // i18next-extract-mark-context-next-line ["critical","veryLow","low","good","great","amazing"]
  return t("common.laraScore.score", { context });
}

export function userRoleLocale(t: TFunction, role: OldRoleCode): string {
  // i18next-extract-mark-context-next-line ["admin", "hrbp", "manager", "integrations", "viewer"]
  return t("common.userRole.role", { context: role });
}

export function integrationLocale(
  t: TFunction,
  integration: IntegrationHrisType | IntegrationCommunicationType
): string {
  // i18next-extract-mark-context-next-line ["bamboo", "gchat", "gsheet", "hi_bob", "slack", "success_factor", "teams", "whatsapp", "workday", "file", "factorial", "sftp","s3", "visma", "rankmi", "workplace", "vom", "kallidus", "sharepoint_file","sharepoint_folder", "api"]
  return t("common.integrations.integration", { context: integration });
}

export function integrationDescriptionLocale(
  t: TFunction,
  integration: IntegrationHrisType | IntegrationCommunicationType
): string {
  // i18next-extract-mark-context-next-line ["sftp","s3","sharepoint_folder"]
  return t("common.integrations.description", { context: integration, defaultValue: "" });
}

export function teamChangeActionLocale(t: TFunction, action: TeamPendingChangeAction): string {
  // i18next-extract-mark-context-next-line ["creation", "modification"]
  return t("settings.teamManagement.pendingChanges.home.table.action", { context: action });
}

export function syncStatusTitleLocale(t: TFunction, status: SyncTitleStatus): string {
  // i18next-extract-mark-context-next-line ["success", "warning", "error"]
  return t("settings.integrationSettings.sync.messages.title.status", { context: status });
}

export function syncStatusDetailErrorLocale(t: TFunction, typeError: SyncError): string {
  // i18next-extract-mark-context-next-line ["missing", "validation", "validation_server", "creation_disabled"]
  return t("settings.integrationSettings.sync.messages.errors.type", { context: typeError });
}

export function syncStatusDetailsActionLocale(t: TFunction, status: "added" | "removed" | "updated" | "error"): string {
  // i18next-extract-mark-context-next-line ["added", "removed", "updated", "error"]
  return t("settings.integrationSettings.sync.messages.details.action", { context: status });
}

export function filterOperatorsLocale(t: TFunction, operator: FilterOperatorType): string {
  // i18next-extract-mark-context-next-line ["eq", "notEq", "in", "notIn", "gt", "gte", "lt", "lte", "between", "like", "after", "before"]
  return t("common.filters.operator", { context: operator });
}

export function feedbackTypeLocale(t: TFunction, type: FeedbackType): string {
  // i18next-extract-mark-context-next-line ["from_chat", "proactive", "enps"]
  return t("feedback.type.feedback", { context: type });
}

export function ensFavorabilityLocale(t: TFunction, favorability: EnpsFavorability): string {
  // i18next-extract-mark-context-next-line ["promoter", "passive", "detractor"]
  return t("enps.favorability.favorability", { context: favorability });
}

export function mappableLaraFieldLocale(t: TFunction, field: IntegrationMappableField, fallback?: string): string {
  // i18next-extract-mark-context-next-line ["email", "firstName", "lastName", "nickname", "gender", "birthdate", "avatar", "phone", "jobTitle", "locale", "contactEnabled", "contactDisabled", "cohortReference", "startedWorkingDate", "hrmsReference", "principalManagerId", "hrbpIds", "workModality", "teamNames", "department", "subDepartment", "division", "location", "city", "country", "leavingDate", "leavingReasonType", "leavingReasonDetail", "employeeGrade", "faqsEnabled",  "faqsDisabled", "communicationsPriorities", "companyName", "performanceScore"]
  return t("settings.integrationSettings.install.steps.mapFields.laraField", {
    context: field,
    defaultValue: fallback,
  });
}

export function dateFieldReferenceLabel(t: TFunction, dateFieldReference: DateFieldReference): string {
  // i18next-extract-mark-context-next-line ["answered_at", "chat_created_at", "detected_at", "opened_at", "closed_at"]
  return t("common.filters.dateFieldReference.label", { context: dateFieldReference });
}

export function leavingReasonTypeLocale(t: TFunction, reasonType: LeavingReasonType): string {
  // i18next-extract-mark-context-next-line ["quit", "fired", "no_show"]
  return t("common.leavingReasonType", { context: reasonType });
}

export function boardAccessScopeLocale(t: TFunction, accessScope: Board["accessScope"]): string {
  // i18next-extract-mark-context-next-line ["organization","restricted"]
  return t("board.list.card.accessScopeToText", { context: accessScope });
}

export function boardAccessScopeDescriptionLocale(t: TFunction, accessScope: Board["accessScope"] | "shared"): string {
  // i18next-extract-mark-context-next-line ["organization","restricted", "shared"]
  return t("board.list.card.accessScopeTooltip", { context: accessScope });
}

export function segmentOwnerTypeLocale(t: TFunction, value: SegmentOwnerType | "none"): string {
  // i18next-extract-mark-context-next-line ["hrbp", "manager", "team", "role", "user", "none"]
  return t("common.segmentOwnerType.type", { context: value });
}

export function teamTypeLocale(t: TFunction, value: TeamType): string {
  // i18next-extract-mark-context-next-line ["direct_reports", "indirect_reports", "custom"]
  return t("common.teamType.type", { context: value });
}

export function shortTeamTypeLocale(t: TFunction, value: TeamType): string {
  // i18next-extract-mark-context-next-line ["direct_reports", "indirect_reports", "custom"]
  return t("common.teamType.shortType", { context: value });
}

export function myTeamTypeLocale(t: TFunction, value: Exclude<TeamType, TeamType.CUSTOM>): string {
  // i18next-extract-mark-context-next-line ["direct_reports", "indirect_reports"]
  return t("common.teamType.myTeamType", { context: value });
}

export function employeeStateLocale(t: TFunction, value: EmployeeStateValue): string {
  // i18next-extract-mark-context-next-line ["pre_onboarding", "offboarding", "inactive", "active"]
  return t("common.employeeState", { context: value });
}

export function genreLocale(t: TFunction, gender: Gender): string {
  // i18next-extract-mark-context-next-line ["male", "female", "non_binary"]
  return t("common.genre", { context: gender, defaultValue: gender });
}
